<template>
  <CCard body-wrapper>
    <h1>
      Склад {{warehouse.name}}
    </h1>
    <CForm
      class="mt-5"
      @submit.prevent="update"
    >
      <CCard>
        <CCardBody>
          <CInput
            v-if="warehouse.sourceId"
            label="Источник"
            value="Создано на основе 1С"
            disabled
          >
            <template #append>
              <CButton size="sm" variant="outlined" color="info" @click="$router.push({name: 'admin.one_s_setting.show', params: { id: warehouse.sourceId }})">
                Настроить
              </CButton>
            </template>
          </CInput>
          <CInput
            label="Полный адрес"
            :value="address"
            @input="changeAddress"
          >
            <template #append>
              <CButton
                v-if="addressIdentified != null"
                variant="outline"
                size="sm"
                :color="addressIdentified ? 'info' : 'danger'"
                :disabled="!addressIdentified"
                @click="showAddressInfo = !showAddressInfo"
              >
                Подробнее
              </CButton>
            </template>
          </CInput>
          <address-parts v-if="warehouse.address" :address="warehouse.address" :show="showAddressInfo" />
          <hr>
          <CRow>
            <CCol col="auto">
              <CButton
                color="primary"
                type="submit"
                :disabled="form.inProcessing"
              >
                <template v-if="form.inProcessing">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span class="sr-only">Подождите...</span>
                </template>
                <template v-else>
                  Сохранить
                </template>
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CForm>
  </CCard>
</template>

<script>
import FormMixins from '@/mixins/form-mixins';
import Swal from 'sweetalert2';
import AddressParts from '@/components/address-parts';

export default {
  name: 'WarehouseForm',
  components: {
    AddressParts,
  },
  props: {
    warehouse: Object,
  },
  mixins: [FormMixins],
  data() {
    return {
      hidePassword: true,
      showAddressInfo: false,
      form: {
        inProcessing: false,
        errors: {}
      }
    }
  },
  computed: {
    address() {
      if (!this.warehouse.address) {
        return ''
      }

      return this.warehouse.address.fullAddress
    },
    addressIdentified() {
      if (!this.warehouse.address) {
        return null
      }

      return this.warehouse.address.identified;
    },
    requestForm() {
      const res =  {...this.warehouse, addressAttributes: this.warehouse.address};
      delete res.address;
      return res;
    }
  },
  methods: {
    changeAddress(address) {
      if (!this.warehouse.address) {
        this.warehouse.address = {}
      }
      this.warehouse.address.fullAddress = address;
    },
    async update() {
      this.form.errors = {};

      this.form.inProcessing = true;
      try {
        const {data} = await this.$axios.put(`/api/warehouses/${this.warehouse.id}`, this.requestForm);
        this.$emit('update', data);
        await Swal.fire(
          'Успешно',
          'Склад обновлен',
          'success',
        );
      } catch (e) {
        if (this.hasErrorsInResponse(e)) {
          this.form.errors = e.response.data;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            text: 'Внутренняя ошибка при сохранении склада',
          })
        }
      } finally {
        this.form.inProcessing = false;
      }
    },
    tcInput(tag) {
      this.warehouse.allowedTc.push(tag);
    },
    tcRemove(value) {
      const index = this.warehouse.allowedTc.findIndex(el => el == value.name);
      this.warehouse.allowedTc.splice(index, 1);
    }
  }
}
</script>
