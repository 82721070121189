<template>
  <CCard body-wrapper>
    <h3>Склады</h3>
    <CForm
      class="mt-5"
      @submit.prevent="update"
    >
      <CDataTable
        v-if="!loading"
        :items="stores"
        :fields="tableFields"
        hover
        :noItemsView="{ noItems: 'Ошибка загрузки' }"
        :loading="loading"
      >
        <template #name="{item}">
          <td class="py-2 middle-align">
            {{ item.name }}
          </td>
        </template>
        <template #externalStore="{item}">
          <td class="py-2 middle-align">
            <CSelect
              style="margin-bottom: 0px;"
              :disabled="updating"
              :options="storesOptions"
              @update:value="externalStoreSetup(item.id, $event)"
              :value="findSelectedStore(item.id)"
            />
          </td>
        </template>
      </CDataTable>
      <hr />
      <loading-button :loading="updating"/>
    </CForm>

    <CElementCover v-if="loading" :opacity="0.8" :style="{zIndex: 2}" />
  </CCard>
</template>

<script>
import LoadingButton from '@/components/loading-button';
import Swal from 'sweetalert2';
import {get} from 'lodash';

export default {
  name: 'StoresSetup',
  components: {
    LoadingButton
  },
  props: {
    warehouseId: [String, Number]
  },
  data() {
    return {
      stores: [],
      externalStores: [],
      loading: false,
      updating: false,
    }
  },
  computed: {
    tableFields() {
      return [
        {key: 'name', label: 'Склад ФФ'},
        {key: 'externalStore', label: 'Склад МС'}
      ]
    },
    storesOptions() {
      return this.externalStores.map(el => ({label: el.name, value: el.id})).concat({label: 'Не выбрано', value: null});
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    externalStoreSetup(storeId, externalStoreId) {
      const index = this.externalStores.findIndex(el => el.id == externalStoreId);
      if (index == -1) {
        return
      }

      const resource = this.externalStores[index];

      this.externalStores.splice(index, 1, {...resource, storeId});
    },
    findSelectedStore(storeId) {
      const element = this.externalStores.find(el => el.storeId == storeId);
      return get(element, 'id', null);
    },
    async load() {
      this.loading = true;
      try {
        let response = null;
        response = await this.$axios.get(`/api/warehouses/${this.warehouseId}/stores`);
        this.stores = response.data;
        await this.$axios.post('/api/moysklad/force_stores_sync');
        response = await this.$axios.get(`/api/external_stores`);
        this.externalStores = response.data;
      } finally {
        this.loading = false;
      }
    },
    async update() {
      this.updating = true;
      try {
        await this.$axios.post(`/api/warehouses/${this.warehouseId}/stores/batch`, this.stores)
        await this.$axios.post(`/api/external_stores/batch`, this.externalStores);
        await Swal.fire(
          'Успешно',
          'Настройки виртуальных складов обновлены',
          'success',
        );
      } finally {
        this.updating = false;
      }
    }
  }
}
</script>
