<template>
  <div>
    <warehouse-form v-if="warehouse" :warehouse="warehouse" />
    <stores-form :warehouse-id="warehouseId" />
    <CElementCover
      v-if="loading"
      :opacity="0.8"
    >
      <CSpinner
        class="custom-spinner"
        color="info"
      />
    </CElementCover>
  </div>
</template>

<script>
import FormMixins from '@/mixins/form-mixins';
import Swal from 'sweetalert2';
import WarehouseForm from './form';
import StoresForm from './stores';

export default {
  name: 'Show',
  components: {
    WarehouseForm,
    StoresForm
  },
  mixins: [
    FormMixins,
  ],
  mounted() {
    this.load();
  },
  data() {
    return {
      warehouse: null,
      loading: false,
    };
  },
  computed: {
    warehouseId() {
      return this.$route.params.id
    }
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const {data} = await this.$axios.get(`/api/warehouses/${this.warehouseId}`);
        this.warehouse = data;
      } catch(e) {
        console.log(e);
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Что-то пошло не так!',
        }).then(() => {
          this.$router.replace({name: 'admin.warehouses.index'});
        })
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped>

</style>
